import { translations } from './translations';

/**
 * This function has two roles:
 * 1) If the `id` is empty it assings something so does i18next doesn't throw error. Typescript should prevent this anyway
 * 2) It has a hand-picked name `_t` (to be short) and should only be used while using objects instead of strings for translation keys
 * `internals/extractMessages/stringfyTranslations.js` script converts this to `t('a.b.c')` style before `i18next-scanner` scans the file contents
 * so that our json objects can also be recognized by the scanner.
 */
const _t = (id: string, ...rest: any[]): [string, ...any[]] => {
  if (!id) {
    id = '_NOT_TRANSLATED_';
  }
  return [id, ...rest];
};

export const messages = {
  header: {
    logout: () => _t(translations.header.logout, 'Logout')
  },
  sidebar: {
    analytics: () => _t(translations.sidebar.analytics, 'ANALYTICS'),
    bigMirrors: () => _t(translations.sidebar.bigMirrors, 'BIG MIRRORS'),
    ARProjects: () => _t(translations.sidebar.ARProjects, 'AR PROJECTS'),
    arProjects: () => _t(translations.sidebar.arProjects, 'AR Projects'),
    userManagement: () =>
      _t(translations.sidebar.userManagement, 'User Management'),
    peopleCounter: () =>
      _t(translations.sidebar.peopleCounter, 'People Counter'),
    clickTracking: () =>
      _t(translations.sidebar.clickTracking, 'User Click Tracking'),
    survey: () => _t(translations.sidebar.survey, 'User Survey'),
    categories: () => _t(translations.sidebar.categories, 'Categories'),
    setupMirrors: () => _t(translations.sidebar.setupMirrors, 'Setup Mirrors'),
    arMasterTemplates: () =>
      _t(translations.sidebar.arMasterTemplates, 'AR Master Templates'),
    sketchFab: () => _t(translations.sidebar.sketchFab, 'Sketchfab'),
    userList: () => _t(translations.sidebar.userList, 'User List'),
    data: () => _t(translations.sidebar.data, 'Data'),
    chart: () => _t(translations.sidebar.chart, 'Chart'),
    snapList: () => _t(translations.sidebar.snapList, 'Snap List'),
    contentList: () => _t(translations.sidebar.contentList, 'Content List'),
    mirrorType: () => _t(translations.sidebar.mirrorType, 'Mirror Type'),
    mirrors: () => _t(translations.sidebar.mirrors, 'Mirrors'),
    profiles: () => _t(translations.sidebar.profiles, 'Profiles'),
    arPreview: () => _t(translations.sidebar.arPreview, 'AR Preview'),
    quiz: () => _t(translations.sidebar.quiz, 'Quiz'),
    group: () => _t(translations.sidebar.group, 'Group'),
    groupList: () => _t(translations.sidebar.groupList, 'Group list'),
    helpMenu: () => _t(translations.sidebar.helpMenu, 'Help menu'),
    premierePro: () => _t(translations.sidebar.premierePro, 'Premiere Pro'),
    editor: () => _t(translations.sidebar.editor, 'Editor'),
    audit: () => _t(translations.sidebar.audit, 'Audit'),
    summary: () => _t(translations.sidebar.summary, 'Summary'),
    details: () => _t(translations.sidebar.details, 'Details'),
    videosList: () => _t(translations.sidebar.videosList, 'Videos list'),
    qrImagesList: () => _t(translations.sidebar.qrImagesList, 'QR Images List'),
    arPamphlet: () => _t(translations.sidebar.arPamphlet, 'AR Pamphlet'),
    arNameCard: () => _t(translations.sidebar.arNameCard, 'AR Name Card'),
    helpdesk: () => _t(translations.sidebar.helpdesk, 'Helpdesk'),
    incidentDetail: () =>
      _t(translations.sidebar.incidentDetail, 'Incident Detail'),
    metaverse: () => _t(translations.sidebar.metaverse, 'metaverse'),
    metaSetting: () =>
      _t(translations.sidebar.metaSetting, 'Metaverse Settings'),
    map: () => _t(translations.sidebar.map, 'Map'),
    world: () => _t(translations.sidebar.world, 'World'),
    area: () => _t(translations.sidebar.area, 'Area'),
    weather: () => _t(translations.sidebar.weather, 'Weather'),
    feedback: () => _t(translations.sidebar.feedback, 'Feedback'),
    billboard: () => _t(translations.sidebar.billboard, 'Billboard'),
    announcementText: () =>
      _t(translations.sidebar.announcementText, 'Announcement Text'),
      monitoring: () =>
      _t(translations.sidebar.monitoring, 'Monitoring'),
      arScanNamecard: () =>
      _t(translations.sidebar.arScanNamecard, 'Scan statistics'),
      arScanPamphlet: () =>
      _t(translations.sidebar.arScanPamphlet, 'Scan statistics'),
  },
  main: {
    name: () => _t(translations.main.name, 'Name'),
    description: () => _t(translations.main.description, 'Description'),
    profile: () => _t(translations.main.profile, 'Profile'),
    adOverlay: () => _t(translations.main.adOverlay, 'Ad Overlay'),
    videoRecord: () => _t(translations.main.videoRecord, 'Video Record'),
    remote: () => _t(translations.main.remote, 'Remote'),
    configure: () => _t(translations.main.configure, 'Configure'),
    delete: () => _t(translations.main.delete, 'Delete'),
    all: () => _t(translations.main.all, 'All'),
    emotion: () => _t(translations.main.emotion, 'Emotion'),
    online: () => _t(translations.main.online, 'Online'),
    offline: () => _t(translations.main.offline, 'Offline'),
    inputType: () => _t(translations.main.inputType, 'Input Type'),
    mirrorType: () => _t(translations.main.mirrorType, 'Mirror Type'),
    group: () => _t(translations.sidebar.group, 'Group'),
    owner: () => _t(translations.main.owner, 'owner'),
    role: () => _t(translations.main.role, 'Role'),
    activated: () => _t(translations.main.activated, 'Activated'),
    advertiseMode: () =>
      _t(translations.main.advertiseMode, 'Advertisement Mode'),
    content: () => _t(translations.main.content, 'Content'),
    gender: () => _t(translations.main.gender, 'Gender'),
    age: () => _t(translations.main.age, 'Age'),
    review: () => _t(translations.main.review, 'Review'),
    question: () => _t(translations.main.question, 'Question'),
    datetime: () => _t(translations.main.datetime, 'Date Time'),
    today: () => _t(translations.main.today, 'Today'),
    last7DaysByDay: () =>
      _t(translations.main.last7DaysByDay, 'Last 7 days (By Day)'),
    last7DaysByTime: () =>
      _t(translations.main.last7DaysByTime, 'Last 7 days (By Time)'),
    last30DaysByDay: () =>
      _t(translations.main.last30DaysByDay, 'Last 30 days (By Day)'),
    last30DaysByTime: () =>
      _t(translations.main.last30DaysByTime, 'Last 30 days (By Time)'),
    custom: () => _t(translations.main.custom, 'Custom (By Time)'),
    customByDay: () => _t(translations.main.customByDay, 'Custom (By Day)'),
    countByPeople: () => _t(translations.main.countByPeople, 'Count by people'),
    stayedLongerThan3Second: () =>
      _t(
        translations.main.stayedLongerThan3Second,
        'Stayed longer than 3 second'
      ),
    sevenDaysAgo: () => _t(translations.main.sevenDaysAgo, '7 days ago'),
    thirtyDaysAgo: () => _t(translations.main.thirtyDaysAgo, '30 days ago'),
    language: () => _t(translations.main.language, 'Profile Language'),
    japanese: () => _t(translations.main.japanese, 'Japanese'),
    english: () => _t(translations.main.english, 'English'),
    vietnamese: () => _t(translations.main.vietnamese, 'Vietnamese'),
    placeholder: {
      reservationUrl: () =>
        _t(
          translations.main.placeholder.reservationUrl,
          'Please enter the store url'
        ),
      reservationMessage: () =>
        _t(
          translations.main.placeholder.reservationMessage,
          'Please fill in a custom message'
        ),
      arNamecardInscreenDisplay: () =>
        _t(
          translations.main.placeholder.arNamecardInscreenDisplay,
          'Your text.\nYour text.\nYour text.\n'
        ),
      arPamphletAssociateName: () =>
        _t(
          translations.main.placeholder.arPamphletAssociateName,
          'Your text.\nYour text.\nYour text.\n'
        ),
      enterBothValue: () =>
        _t(translations.main.placeholder.enterBothValue, 'Enter or '),
      enterName: () => _t(translations.main.placeholder.enterName, 'Enter'),
      enterQrDescription: () => _t(translations.main.placeholder.enterQrDescription, 'Enter'),
      enterQrDuration: () => _t(translations.main.placeholder.enterQrDuration, 'Enter'),
      phone: () =>
        _t(
          translations.main.placeholder.phone,
          'Phone Number (Eg：08012345678）'
        ),
      fileUploader: () =>
        _t(
          translations.main.placeholder.fileUploader,
          'Drag "n" drop file here, or click to select files'
        ),
      arNameCardName: () =>
        _t(translations.main.placeholder.arNameCardName, 'Enter'),
      arPamphletName: () =>
        _t(translations.main.placeholder.arPamphletName, 'Enter'),
      arTemplateName: () =>
        _t(translations.main.placeholder.arTemplateName, 'Enter'),
      ARProjectShopURL: () =>
        _t(translations.main.placeholder.ARProjectShopURL, ''),
      inputTags: () =>
        _t(
          translations.main.placeholder.inputTags,
          'Input tags (splitted by ',
          ')'
        ),
      helpdeskChat: () =>
        _t(translations.main.placeholder.helpdeskChat, 'Type something...'),
      arAflacInternalId: () =>
        _t(
          translations.main.placeholder.arAflacInternalId,
          'Enter digits (maximum: 32 digits)'
        )
    },
    tabs: {
      reservation: () => _t(translations.main.tabs.reservation, 'Reservation'),
      neutralVideos: () =>
        _t(translations.main.tabs.neutralVideos, 'Neutral Video'),
      voiceovers: () => _t(translations.main.tabs.voiceovers, 'Voiceovers'),
      photos: () => _t(translations.main.tabs.photos, 'Photos'),
      contents: () => _t(translations.main.tabs.contents, 'Contents'),
      mirrors: () => _t(translations.main.tabs.mirrors, 'Mirrors'),
      snaps: () => _t(translations.main.tabs.snaps, 'Snaps'),
      qrImages: () => _t(translations.main.tabs.qrImages, 'QrImages'),
    },
    startDate: () => _t(translations.main.startDate, 'Start Date'),
    hourly: () => _t(translations.main.hourly, 'HOURLY'),
    threeTimes: () => _t(translations.main.threeTimes, 'TIMES'), // morning - nooon - night,
    clickedCount: () => _t(translations.main.clickedCount, 'Clicked Count'),
    addUser: () => _t(translations.main.addUser, 'Add user'),
    addGroup: () => _t(translations.main.addGroup, 'Add group'),
    password: () => _t(translations.main.password, 'Password'),
    newPassword: () => _t(translations.main.newPassword, 'New password'),
    oldPassword: () => _t(translations.main.oldPassword, 'Old password'),
    confirmPassword: () =>
      _t(translations.main.confirmPassword, 'Confirm password'),
    zipCode: () => _t(translations.main.zipCode, 'Zip code'),
    active: () => _t(translations.main.active, 'Active'),
    inactive: () => _t(translations.main.inactive, 'Active'),
    save: () => _t(translations.main.save, 'Save'),
    cancel: () => _t(translations.main.cancel, 'Cancel'),
    generalProfile: () =>
      _t(translations.main.generalProfile, 'GENERAL PROFILE'),
    generalMirror: () => _t(translations.main.generalMirror, 'GENERAL MIRROR'),
    deleteConfirmation: () =>
      _t(translations.main.deleteConfirmation, 'Delete Confirmation'),
    deleteConfirmMessage: () =>
      _t(
        translations.main.deleteConfirmMessage,
        'This action can not be undo. Are you sure to delete?'
      ),
    addMirror: () => _t(translations.main.addMirror, 'Add mirror'),
    addProfile: () => _t(translations.main.addProfile, 'Add profile'),
    addMirrorType: () => _t(translations.main.addMirrorType, 'Add mirror type'),
    status: () => _t(translations.main.status, 'Status'),
    fromDate: () => _t(translations.main.fromDate, 'From date'),
    toDate: () => _t(translations.main.toDate, 'To date'),
    export: () => _t(translations.main.export, 'Export'),
    exportCSV: () => _t(translations.main.exportCSV, 'Export'),
    exportStatsCSV: () => _t(translations.main.exportStatsCSV, 'Export Stats'),
    exportSearchResults: () => _t(translations.main.exportSearchResults, 'Export Search Results'),
    exportPeopleCounter: () =>
      _t(
        translations.main.exportPeopleCounter,
        'Export People Counter Raw Data'
      ),
    exportPeopleNeutralVideo: () =>
      _t(
        translations.main.exportPeopleNeutralVideo,
        'Export People Neutral Video Tracking'
      ),
    hours: () => _t(translations.main.hours, 'Hours'),
    peopleCount: () => _t(translations.main.peopleCount, 'People count'),
    male: () => _t(translations.main.male, 'Male'),
    female: () => _t(translations.main.female, 'Female'),
    unknownGender: () => _t(translations.main.unknownGender, 'Unknown gender'),
    unknownAge: () => _t(translations.main.unknownAge, 'Unknown age'),
    sad: () => _t(translations.main.sad, 'Sad'),
    neutral: () => _t(translations.main.neutral, 'Neutral'),
    angry: () => _t(translations.main.angry, 'Angry'),
    fear: () => _t(translations.main.fear, 'Fear'),
    happy: () => _t(translations.main.happy, 'Happy'),
    surprise: () => _t(translations.main.surprise, 'Surprise'),
    unknownEmotion: () =>
      _t(translations.main.unknownEmotion, 'Unknown emotion'),
    questionMarkPharse1: () =>
      _t(
        translations.main.questionMarkPharse1,
        'Large mirrors keep track of the estimated number of pedestrians passing or staying.'
      ),
    questionMarkPharse2: () =>
      _t(
        translations.main.questionMarkPharse2,
        'Pedestrians, age, sex, and emotion are categorized separately.'
      ),
    questionMarkPharse3: () =>
      _t(
        translations.main.questionMarkPharse3,
        'By specifying the category at the top, you can check the data of a specific mirror and a specific time period in chart format.'
      ),
    questionMarkPharse4: () =>
      _t(
        translations.main.questionMarkPharse4,
        'You can use the items at the bottom to export the data in CSV format according to your needs.'
      ),
    error: {
      required: () =>
        _t(translations.main.error.required, 'This field is required'),
      duplicated: () =>
        _t(translations.main.error.duplicated, 'Name is duplicated'),
      passwordNotMatch: () =>
        _t(translations.main.error.passwordNotMatch, 'Password does not match'),
      passwordResetFail: () =>
        _t(
          translations.main.error.passwordResetFail,
          'Fail to reset your password'
        ),
      durationVideo: () =>
        _t(
          translations.main.error.durationVideo,
          'Please upload video shorter than 30 seconds!'
        ),
      groupHasUsers: () =>
        _t(
          translations.main.error.groupHasUsers,
          'Failed to delete group.\nReason: A group cannot be deleted if at least one user belongs to it.'
        ),
      oldPasswordNotMatch: () =>
        _t(
          translations.main.error.oldPasswordNotMatch,
          'Your current password is incorrect. Please try again.'
        ),
      currentPasswordAndNewPasswordAreTheSame: () =>
        _t(
          translations.main.error.currentPasswordAndNewPasswordAreTheSame,
          'The current password and new password are the same. Please try again.'
        ),
      quizHasUsers: () =>
        _t(
          translations.main.error.quizHasUsers,
          'Failed to delete quiz.\nReason: A quiz cannot be deleted if at least one user belongs to it.'
        ),
      outOfUnscreenQuota: () =>
        _t(translations.main.error.outOfUnscreenQuota, 'Out of unscreen quota'),
      unscreenFail: () =>
        _t(
          translations.main.error.unscreenFail,
          'An error occurred during creation. Please try the operation again.'
        ),
      answerIsAMinimum2Answer: () =>
        _t(
          translations.main.error.answerIsAMinimum2Answer,
          'The answer is a minimum of two answers.'
        ),
      mixedVoiceover: () =>
        _t(
          translations.main.error.mixedVoiceover,
          'Step 1 or Step 2 cannot be mixed with Step fixed duration'
        ),
      pleaseUploadVoiceoverFiles: () =>
        _t(
          translations.main.error.pleaseUploadVoiceoverFiles,
          'Please upload voiceover files.'
        ),
      pleaseUploadGifFile: () =>
        _t(
          translations.main.error.pleaseUploadGifFile,
          'Please upload GIF file.'
        ),
      metaverseBillboardResolution: () =>
        _t(
          translations.main.error.metaverseBillboardResolution,
          'Invalid resolution'
        ),
        metaverseThumbnailFileNameMustMatchVideoFileName: () =>
        _t(
          translations.main.error.metaverseThumbnailFileNameMustMatchVideoFileName,
          'Filename must match'
        ),
      nan: () =>
          _t(translations.main.error.nan, 'Please enter a number'),  
      positive: () =>
        _t(translations.main.error.positive, 'Please enter a positive number'),  
      integer: () =>
        _t(translations.main.error.integer, 'Please enter integer'),  
    },
    changePassword: {
      changePasswordText: () =>
        _t(
          translations.main.changePasswordText,
          'Enter new password in textarea below'
        ),
      changePassword: () =>
        _t(translations.main.changePassword, 'Change Password'),
      min: () => _t(translations.rules.min, 'Longer than 8 characters'),
      number: () =>
        _t(translations.rules.number, 'Contains at least 1 number '),
      lCaseChar: () =>
        _t(
          translations.rules.lCaseChar,
          'Contains at least 1 lowercase character'
        ),
      uCaseChar: () =>
        _t(
          translations.rules.uCaseChar,
          'Contains at least 1 uppercase character'
        )
    },
    success: {
      save: () => _t(translations.main.success.save, 'Save successfully'),
      delete: () => _t(translations.main.success.delete, 'Delete successfully'),
      passwordReset: () =>
        _t(
          translations.main.success.passwordReset,
          'Password reset successfully'
        ),
      changePasswordSuccessfully: () =>
        _t(
          translations.main.success.changePasswordSuccessfully,
          'Change password successfully'
        ),
      promote: () =>
        _t(translations.main.success.promote, 'promote successfully')
    },
    upload: () => _t(translations.main.upload, 'Upload'),
    mirrorTypeConfiguration: () =>
      _t(
        translations.main.mirrorTypeConfiguration,
        'Mirror Type Configuration'
      ),
    overrideVoiceovers: () =>
      _t(translations.main.overrideVoiceovers, 'Override voiceovers'),
    overridePhotos: () =>
      _t(translations.main.overridePhotos, 'Override photos'),
    enableVoiceover: () =>
      _t(translations.main.enableVoiceover, 'Enable voiceover'),
    enableFixedDuration: () =>
      _t(
        translations.main.enableFixedDuration,
        'Enable voiceover (Fixed Duration)'
      ),
    enableVoiceoverNobody: () =>
      _t(translations.main.enableVoiceoverNobody, 'Enable voiceover nobody'),
    action: () => _t(translations.main.action, 'Action'),
    yes: () => _t(translations.main.yes, 'Yes'),
    no: () => _t(translations.main.no, 'No'),
    enable: () => _t(translations.main.enable, 'Enable'),
    branch: () => _t(translations.main.branch, 'Branch'),
    addVideo: () => _t(translations.main.addVideo, 'Add video'),
    addQrImage: () => _t(translations.main.addQrImage, 'Add qr image'),
    addSnap: () => _t(translations.main.addSnap, 'Add new snap'),
    addContent: () => _t(translations.main.addContent, 'Add new content'),
    fullScreenPhoto: () =>
      _t(translations.main.fullScreenPhoto, 'Full screen photo'),
    footerPhoto: () => _t(translations.main.footerPhoto, 'Footer photo'),
    headerPhoto: () => _t(translations.main.headerPhoto, 'Header photo'),
    video: () => _t(translations.main.video, 'Video'),
    qrImage: () => _t(translations.main.qrImage, 'qr image'),
    thumbnail: () => _t(translations.main.thumbnail, 'Thumbnail'),
    reservation: {
      from: () => _t(translations.main.reservation.from, 'From'),
      to: () => _t(translations.main.reservation.to, 'To'),
      datetimes: () =>
        _t(translations.main.reservation.datetimes, 'DATE/TIMES'),
      urlMessage: () =>
        _t(
          translations.main.reservation.urlMessage,
          'Reservation URL & Message'
        ),
      message: () => _t(translations.main.reservation.message, 'Message'),
      morning: () => _t(translations.main.reservation.morning, 'Morning'),
      noon: () => _t(translations.main.reservation.noon, 'Noon'),
      night: () => _t(translations.main.reservation.night, 'Night')
    },
    step1: {
      title: () => _t(translations.main.step1.title, 'Step1'),
      prefixText: () => _t(translations.main.step1.prefixText, ''),
      suffixText: () => _t(translations.main.step1.suffixText, '')
    },
    step2: {
      title: () => _t(translations.main.step2.title, 'Step2'),
      prefixText: () => _t(translations.main.step2.prefixText, ''),
      suffixText: () => _t(translations.main.step2.suffixText, '')
    },
    step_nobody: {
      title: () => _t(translations.main.step_nobody.title, 'Nobody'),
      prefixText: () => _t(translations.main.step_nobody.prefixText, ''),
      suffixText: () => _t(translations.main.step_nobody.suffixText, '')
    },
    step_fixed_duration: {
      title: () =>
        _t(translations.main.step_fixed_duration.title, 'Fixed duration'),
      prefixText: () =>
        _t(translations.main.step_fixed_duration.prefixText, ''),
      suffixText: () => _t(translations.main.step_fixed_duration.suffixText, '')
    },
    version: {
      title: () => _t(translations.main.version.title, 'Version'),
      staging: () => _t(translations.main.version.staging, 'Staging'),
      prod: () => _t(translations.main.version.prod, 'Prod'),
      qa: () => _t(translations.main.version.qa, 'QA'),
      local: () => _t(translations.main.version.local, 'Local')
    },
    advertiseModeOptions: {
      disabled: () =>
        _t(translations.main.advertiseModeOptions.disabled, 'Disabled'),
      fullScreenPhoto: () =>
        _t(
          translations.main.advertiseModeOptions.fullScreenPhoto,
          'Full screen photo'
        ),
      reservationStatus: () =>
        _t(
          translations.main.advertiseModeOptions.reservationStatus,
          'Reservation status'
        ),
      neutralVideos: () =>
        _t(
          translations.main.advertiseModeOptions.neutralVideos,
          'Neutral videos'
        ),
      neutralVideosPhotos: () =>
        _t(
          translations.main.advertiseModeOptions.neutralVideosPhotos,
          'Neutral videos & photos'
        )
    },
    date: {
      none: () => _t(translations.main.date.none, 'None'),
      name: () => _t(translations.main.date.name, 'Date')
    },
    email: () => _t(translations.main.email, 'Email'),
    forgetPassword: () =>
      _t(translations.main.forgetPassword, 'Forget Password'),
    login: () => _t(translations.main.login, 'Login'),
    resetLinkSentToEmail: () =>
      _t(
        translations.main.resetLinkSentToEmail,
        'Password reset link will be sent to your registered email.'
      ),
    confirm: () => _t(translations.main.confirm, 'Confirm'),
    welcome: () => _t(translations.main.welcome, 'Welcome to Console!'),
    search: () => _t(translations.main.search, 'Search'),
    groupInformation: () =>
      _t(translations.main.groupInformation, 'Group Information'),
    // arNameCardFooter: () => _t(translations.main.arNameCardFooter, 'AR Footer'),
    // arPamphletFooter: () => _t(translations.main.arPamphletFooter, 'AR Footer'),
    addTemplate: () => _t(translations.main.addTemplate, 'Add Template'),
    arTemplateInformation: () =>
      _t(translations.main.arTemplateInformation, 'AR Template Information'),
    link: () => _t(translations.main.link, 'Link'),
    previewLink: () => _t(translations.main.previewLink, 'Preview Link'),
    templateName: () => _t(translations.main.templateName, 'Name'),
    templateLink: () => _t(translations.main.templateLink, 'Template Link'),
    resetPassword: () => _t(translations.main.resetPassword, 'Reset password'),
    resetPasswordLabel: () =>
      _t(
        translations.main.resetPasswordLabel,
        'Please enter your new password'
      ),
    backToLogin: () => _t(translations.main.backToLogin, 'Back to login'),
    enableDisable: () => _t(translations.main.enableDisable, 'Enable/Disable'),
    lastLoginDate: () => _t(translations.main.lastLoginDate, 'Last login Date'),
    arProjectInformation: () =>
      _t(translations.main.arProjectInformation, 'AR Project Information'),
    addArProject: () => _t(translations.main.addArProject, 'Add AR Project'),
    arTemplateName: () => _t(translations.main.arTemplateName, 'Template Name'),
    arNameCardName: () => _t(translations.main.arNameCardName, 'Name'),
    arNamecardInscreenDisplay: () =>
      _t(
        translations.main.arNamecardInscreenDisplay,
        'Displayed inscreen infomation'
      ),
    arPamphletAssociateApplicantName: () =>
      _t(translations.main.arPamphletAssociateApplicantName, 'Name'),
    arPamphletApplicantName: () =>
      _t(translations.main.arPamphletApplicantName, 'Name'),
    arPamphletAssociateName: () =>
      _t(translations.main.arPamphletAssociateName, 'Associate name'),
    phoneNumber: () => _t(translations.main.phoneNumber, 'Phone number'),
    projectLink: () => _t(translations.main.projectLink, 'Project link'),
    created: () => _t(translations.main.created, 'Created'),
    user: () => _t(translations.main.user, 'User'),
    webarCreator: () =>
      _t(translations.main.webarCreator, 'webAR Creator Name'),
    shopPhone: () => _t(translations.main.shopPhone, 'Shop Phone'),
    website: () => _t(translations.main.website, 'Website'),
    voice: () => _t(translations.main.voice, 'Voice'),
    tts: () => _t(translations.main.tts, 'Azure TTS'),
    removeBackground: () =>
      _t(translations.main.removeBackground, 'Remove background'),
    none: () => _t(translations.main.none, 'None'),
    videoText: () => _t(translations.main.videoText, 'Video text'),
    reset: () => _t(translations.main.reset, 'Reset'),
    arProjectConfigure: () =>
      _t(translations.main.arProjectConfigure, 'Configure'),
    arProjectOwner: () => _t(translations.main.arProjectOwner, 'Owner'),
    arProjectTemplateName: () =>
      _t(translations.main.arProjectTemplateName, 'Template Name'),
    arProjectVideo: () => _t(translations.main.arProjectVideo, 'Video'),
    arProjectEditing: () =>
      _t(translations.main.arProjectEditing, 'AR Project Information'),
    invalidVideo: () =>
      _t(
        translations.main.invalidVideo,
        'Invalid video type (required .mp4, webm, ogv, quicktime, gif)'
      ),
    sizeVideo: () =>
      _t(translations.main.sizeVideo, 'Invalid video size (required max 50MB)'),
    durationVideo: () =>
      _t(
        translations.main.durationVideo,
        'Please upload video shorter than 30 seconds!'
      ),
    audioRequired: () =>
      _t(translations.main.audioRequired, 'Invalid audio type (required .mp3)'),
    chooseFile: () => _t(translations.main.chooseFile, 'Choose File'),
    noFileChosen: () => _t(translations.main.noFileChosen, 'No file chosen'),
    uploadCredit: () => _t(translations.main.uploadCredit, 'Upload credit'),
    times: () => _t(translations.main.times, 'times'),
    addQuiz: () => _t(translations.main.addQuiz, 'Add quiz'),
    quizInformation: () =>
      _t(translations.main.quizInformation, 'Quiz information'),
    answer: () => _t(translations.main.answer, 'Answer'),
    options: () => _t(translations.main.options, 'Options'),
    explanation: () => _t(translations.main.explanation, 'Explanation'),
    explanationImage: () =>
      _t(translations.main.explanationImage, 'Explanation image'),
    category: () => _t(translations.main.category, 'Category'),
    difficulty: () => _t(translations.main.difficulty, 'Difficulty'),
    users: () => _t(translations.main.users, 'Users'),
    cannotDeleteProfile: () =>
      _t(translations.main.cannotDeleteProfile, 'Cannot Delete Profile'),
    cannotDeleteProfileMessage: () =>
      _t(
        translations.main.cannotDeleteProfileMessage,
        'There are mirrors assigned to the profile. Please reassign the mirrors to other profile first.'
      ),
    expiredDate: () => _t(translations.main.expiredDate, 'Expired date'),
    assigned: () => _t(translations.main.assigned, 'Assigned'),
    totalPeople: () => _t(translations.main.totalPeople, 'Total people'),
    contentInformation: () =>
      _t(translations.main.contentInformation, 'Content Information'),
    gameNameEng: () => _t(translations.main.gameNameEng, 'Game name (eng)'),
    gameNameJpn: () => _t(translations.main.gameNameJpn, 'Game name (jpn)'),
    gameNameVi: () => _t(translations.main.gameNameVi, 'Game name (vi)'),
    descriptionEng: () =>
      _t(translations.main.descriptionEng, 'Description (eng)'),
    descriptionJpn: () =>
      _t(translations.main.descriptionJpn, 'Description (jpn)'),
    descriptionVi: () =>
      _t(translations.main.descriptionVi, 'Description (vi)'),
    logoImage: () => _t(translations.main.logoImage, 'Logo image'),
    snapInformation: () =>
      _t(translations.main.snapInformation, 'Snap information'),
    edge: () => _t(translations.main.edge, 'Edge'),
    beta: () => _t(translations.main.beta, 'Beta'),
    candidate: () => _t(translations.main.candidate, 'Candidate'),
    stable: () => _t(translations.main.stable, 'Stable'),
    sync: () => _t(translations.main.sync, 'Sync'),
    syncContentsConfirmation: () =>
      _t(
        translations.main.syncContentsConfirmation,
        'Sync Contents Confirmation'
      ),
    syncContentsConfirmationMessage: () =>
      _t(
        translations.main.syncContentsConfirmationMessage,
        "This action will apply the latest mirror type's contents to this profile. Do you want to continue?"
      ),
    override: () => _t(translations.main.override, 'Override'),
    modifiedDate: () => _t(translations.main.modifiedDate, 'Modified date'),
    createdDate: () => _t(translations.main.createdDate, 'Created date'),
    reservationStatus: () =>
      _t(translations.main.reservationStatus, 'Reservation status'),
    noData: () => _t(translations.main.noData, 'No data'),
    clear: () => _t(translations.main.clear, 'Clear'),
    mirror: () => _t(translations.main.mirror, 'Mirror'),
    dataType: () => _t(translations.main.dataType, 'Data type'),
    updatedBy: () => _t(translations.main.updatedBy, 'Updated by'),
    ledColor: () => _t(translations.main.ledColor, 'LED Color'),
    thereArentAnyMirrorsAssign: () =>
      _t(
        translations.main.thereArentAnyMirrorsAssign,
        "There aren't any mirrors assigned to user"
      ),
    onlineProfileLegend: () =>
      _t(translations.main.onlineProfileLegend, 'Online'),
    offlineProfileLegend: () =>
      _t(translations.main.offlineProfileLegend, 'Offline'),
    overrideProfileLegend: () =>
      _t(translations.main.overrideProfileLegend, 'Override'),
    noOverrideProfileLegend: () =>
      _t(translations.main.noOverrideProfileLegend, 'No Override'),
    totalRecords: () => _t(translations.main.totalRecords, 'Total Records'),
    fileSize: () => _t(translations.main.fileSize, 'File size'),
    fileDuration: () => _t(translations.main.fileDuration, 'File duration'),
    top10HighestNumberOfPeopleYesterday: () =>
      _t(
        translations.main.top10HighestNumberOfPeopleYesterday,
        'Top 10 mirrors with the highest number of people (yesterday)'
      ),
    top10HighestNumberOfPeople7Days: () =>
      _t(
        translations.main.top10HighestNumberOfPeople7Days,
        'Top 10 mirrors with the highest number of people (7 days)'
      ),
    top10HighestNumberOfPeople1Month: () =>
      _t(
        translations.main.top10HighestNumberOfPeople1Month,
        'Top 10 mirrors with the highest number of people (1 month)'
      ),
    yesterday: () => _t(translations.main.yesterday, 'Yesterday'),
    videosInformation: () =>
      _t(translations.main.videosInformation, 'Videos information'),
    videosName: () => _t(translations.main.videosName, 'Videos name'),
    qrImageInformation: () =>
      _t(translations.main.qrImageInformation, 'Qr image information'),
    qrImageName: () => _t(translations.main.qrImageName, 'Qr image name'),
    qrImageDuration: () => _t(translations.main.qrImageName, 'Qr image name'),
  
    descriptionDeleteVideo: () =>
      _t(
        translations.main.descriptionDeleteVideo,
        'Deleting this video will also remove this video linked on the profile'
      ),
    addNewVideo: () => _t(translations.main.addNewVideo, 'Add new video'),
    numberOfQrcode: () =>
      _t(translations.main.numberOfQrcode, 'Number of QR code'),
    details: () => _t(translations.sidebar.details, 'Details'),
    showOnlyMine: () => _t(translations.main.showOnlyMine, 'Show only mine'),
    tags: () => _t(translations.main.tags, 'Tags'),
    showMyPurchase: () =>
      _t(translations.main.showMyPurchase, 'Show my purchase'),
    isDownloadable: () =>
      _t(translations.main.isDownloadable, 'Is Downloadable'),
    isAnimated: () => _t(translations.main.isAnimated, 'is animated'),
    polycount: () => _t(translations.main.polycount, 'Polycount'),
    sortBy: () => _t(translations.main.sortBy, 'Sort by'),
    openFilters: () => _t(translations.main.openFilters, 'Open filters'),
    closeFilters: () => _t(translations.main.closeFilters, 'Close filters'),
    keywords: () => _t(translations.main.keywords, 'Keywords'),
    loadMore: () => _t(translations.main.loadMore, 'Load more'),
    page: () => _t(translations.main.page, 'Page'),
    previous: () => _t(translations.main.previous, 'Previous'),
    next: () => _t(translations.main.next, 'Next'),
    type: () => _t(translations.main.type, 'Type'),
    duration: () => _t(translations.main.duration, 'Duration'),
    qrDuration: () => _t(translations.main.qrDuration, 'QR Duration'),
    voiceover: () => _t(translations.main.voiceover, 'Voiceover'),
    preview: () => _t(translations.main.preview, 'Preview'),
    addVoiceover: () => _t(translations.main.addVoiceover, 'Add Voiceover'),
    minutes: () => _t(translations.main.minutes, 'Minutes'),
    seconds: () => _t(translations.main.seconds, 'Seconds'),
    enableVoiceovers: () => _t(translations.main.enableVoiceovers, 'Enable'),
    addMore: () => _t(translations.main.addMore, 'Add More'),
    uploadVoiceoverSuccessfully: () =>
      _t(
        translations.main.uploadVoiceoverSuccessfully,
        'Upload voiceover successfully'
      ),
    shopNameMirror: () => _t(translations.main.shopNameMirror, 'Shop name'),
    addArPamphlet: () => _t(translations.main.addArPamphlet, 'Add AR Pamphlet'),
    arPamphletInformation: () =>
      _t(translations.main.arPamphletInformation, 'AR Pamphlet Information'),
    arPamphletInscreenDisplay: () =>
      _t(
        translations.main.arPamphletInscreenDisplay,
        'In-screen display information'
      ),
    admin: () => _t(translations.main.admin, 'Admin'),
    mirrorOwner: () => _t(translations.main.mirrorOwner, 'Mirror Owner'),
    arOwnerNameCard: () =>
      _t(translations.main.arOwnerNameCard, 'AR Owner Name Card'),
    arOwnerPamphlet: () =>
      _t(translations.main.arOwnerPamphlet, 'AR Owner Pamphlet'),
    arAdmin: () => _t(translations.main.arAdmin, 'AR Admin'),
    userAdmin: () => _t(translations.main.userAdmin, 'User Admin'),
    mirorViewer: () => _t(translations.main.mirorViewer, 'Mirror Viewer'),
    uploadedDate: () => _t(translations.main.uploadedDate, 'Uploaded Date'),
    fileView: () => _t(translations.main.fileView, 'File view'),
    snap: () => _t(translations.main.snap, 'Snap'),
    expiredQRCode: () => _t(translations.main.expiredQRCode, 'Expired QR Code'),
    scanOvertime: () => _t(translations.main.scanOvertime, 'Scan over time'),
    scanByOperatingSystem: () =>
      _t(translations.main.scanByOperatingSystem, 'Scan by operating system'),
    scanByTopCountries: () =>
      _t(translations.main.scanByTopCountries, 'Scan by top countries'),
    scanByTopCities: () =>
      _t(translations.main.scanByTopCities, 'Scan by top cities'),
    country: () => _t(translations.main.country, 'Country'),
    city: () => _t(translations.main.city, 'City'),
    scans: () => _t(translations.main.scans, 'Scans'),
    assignedProfiles: () =>
      _t(translations.main.assignedProfiles, 'Assigned profiles'),
    assignedCountProfiles: () =>
      _t(translations.main.assignedCountProfiles, 'Assigned 0 profiles'),
    footControl: () => _t(translations.main.footControl, 'Foot Control'),
    touchScreen: () => _t(translations.main.touchScreen, 'Touch Screen'),
    handControl: () => _t(translations.main.handControl, 'Hand Control'),
    aruco: () => _t(translations.main.aruco, 'ARUCO'),
    jpn: () => _t(translations.main.jpn, 'JPN'),
    contentsCount: () => _t(translations.main.contentsCount, 'Contents Count'),
    contentVersion: {
      contentVersioning: () =>
        _t(
          translations.main.contentVersion.contentVersioning,
          'Content Versioning'
        ),
      promotion: () =>
        _t(translations.main.contentVersion.promotion, 'Promotion'),
      promote: () => _t(translations.main.contentVersion.promote, 'Promote'),
      versionHitory: () =>
        _t(translations.main.contentVersion.versionHistory, 'Version History'),
      version: () => _t(translations.main.contentVersion.version, 'Version'),
      edgeReleaseDate: () =>
        _t(
          translations.main.contentVersion.releaseDate.edge,
          'Release Date - Edge'
        ),
      betaReleaseDate: () =>
        _t(
          translations.main.contentVersion.releaseDate.beta,
          'Release Date - Beta'
        ),
      candidateReleaseDate: () =>
        _t(
          translations.main.contentVersion.releaseDate.candidate,
          'Release Date - Candidate'
        ),
      stableReleaseDate: () =>
        _t(
          translations.main.contentVersion.releaseDate.stable,
          'Release Date - Stable'
        ),
      releaseNote: () =>
        _t(translations.main.contentVersion.releaseNote, 'Release Note'),
      note: () =>
        _t(
          translations.main.contentVersion.note,
          "Note: Content's version need to be promoted step by step: Edge -> Beta -> Candidate -> Stable"
        ),
      promoteConfirmation: () =>
        _t(
          translations.main.contentVersion.promoteConfirmation,
          'Promotion Confirmation'
        ),
      promoteConfirmationMessage: () =>
        _t(
          translations.main.contentVersion.promoteConfirmationMessage,
          'Promoting new version will trigger live update on the mirrors that are using this branch. Are you sure?'
        )
    },
    snapNoVersionWarning: () =>
      _t(
        translations.main.snapNoVersionWarning,
        "This snap doesn't have any version released on S3"
      ),
    lastUpdated: () => _t(translations.main.lastUpdated, 'Last updated'),
    helpdesk: {
      botGreeting: () =>
        _t(translations.main.helpdesk.botGreeting, 'Hello, can I help you?'),
      botConnectAdmin: () =>
        _t(
          translations.main.helpdesk.botConnectAdmin,
          'Connecting to admin, please wait!'
        ),
      botOtherOption: () =>
        _t(translations.main.helpdesk.botOtherOption, 'Other'),
      botSelectFAQ: () =>
        _t(
          translations.main.helpdesk.botSelectFAQ,
          'Please select one of these FAQs below'
        ),
      askUserInfo: () =>
        _t(
          translations.main.helpdesk.askUserInfo,
          'Please enter contact infomation'
        )
    },
    send: () => _t(translations.main.send, 'Send'),
    backToMenu: () => _t(translations.main.backToMenu, 'Back to menu'),
    contactSupport: () =>
      _t(translations.main.contactSupport, 'Contact Support'),
    fixed: () => _t(translations.main.fixed, 'Fixed'),
    notFixed: () => _t(translations.main.notFixed, 'Not fixed'),
    arAflacInternalId: () => _t(translations.main.arAflacInternalId, 'ID'),
    createdUpdatedUser: () =>
      _t(translations.main.createdUpdatedUser, 'Created/Updated user'),
    lastUpdatedDate: () =>
      _t(translations.main.lastUpdatedDate, 'Last updated user'),
    code: () => _t(translations.main.code, 'Code'),
    color: () => _t(translations.main.color, 'Color'),
    image: () => _t(translations.main.image, 'Image'),
    map: () => _t(translations.main.map, 'Map'),
    world: () => _t(translations.main.world, 'World'),
    text: () => _t(translations.main.text, 'Text'),
    resource: () => _t(translations.main.resource, 'Resource'),
    weather: () => _t(translations.main.weather, 'Weather'),
    sunny: () => _t(translations.main.sunny, 'Clear'),
    cloudy: () => _t(translations.main.cloudy, 'Cloudy'),
    rain: () => _t(translations.main.rain, 'Rain'),
    snow: () => _t(translations.main.snow, 'Snow'),
    pallete: {
      red: () => _t(translations.main.pallete.red, 'Red'),
      green: () => _t(translations.main.pallete.green, 'Green'),
      yellow: () => _t(translations.main.pallete.yellow, 'Yellow'),
      blue: () => _t(translations.main.pallete.blue, 'Blue'),
      cyan: () => _t(translations.main.pallete.cyan, 'Cyan'),
      magenta: () => _t(translations.main.pallete.magenta, 'Magenta')
    },
    feedback: () => _t(translations.main.feedback, 'Feedback'),
    addBillboard: () =>
      _t(translations.main.addBillboard, 'Add Metaverse Billboard'),
    editBillboard: () =>
      _t(translations.main.editBillboard, 'Edit Metaverse Billboard'),
    announcementText: () =>
      _t(translations.main.announcementText, 'Announcement Text'),
    arScanStatistics: () =>
      _t(translations.main.arScanStatistics, 'Scan Statistics'),
    arScanNoData: () => _t(translations.main.arScanNoData, 'No data'),
    chooseVideos: () => _t(translations.main.chooseVideos, 'Choose file'),
    chooseThumbnails: () => _t(translations.main.chooseThumbnails, 'Choose file'),
    operationSystem: () => _t(translations.main.operationSystem, 'Operation System'),
    NO: () => _t(translations.main.NO, 'NO.'),
    latitude: () => _t(translations.main.latitude, 'latitude'),
    longitude: () => _t(translations.main.longitude, 'longitude'),
    scanDate: () => _t(translations.main.scanDate, 'Date'),
    device: () => _t(translations.main.device, 'Device'),
    arStatus: () => _t(translations.main.arStatus, 'Status'),
    copyVideoFromProfile: () => _t(translations.main.copyVideoFromProfile, 'Copy video from profile'),
    metaverseMap: () => _t(translations.main.metaverseMap, 'Metaverse Map'),
    metaverseWorld: () => _t(translations.main.metaverseWorld, 'Metaverse World'),
    metaverseArea: () => _t(translations.main.metaverseArea, 'Metaverse Area'),
    qrDescription: () => _t(translations.main.qrDescription, 'QRdescription'),
    QRImage: () => _t(translations.main.qrImage, 'QRImage'),
    qrDescriptionColor: () => _t(translations.main.qrDescriptionColor, 'QRdescriptionColor'),
    QRdescriptionSize: () => _t(translations.main.QRdescriptionSize, 'QRdescriptionSize'),
    QRdescriptionBold: () => _t(translations.main.QRdescriptionBold, 'QRdescriptionBold'),
    copyQrImageFromProfile: () => _t(translations.main.copyQrImageFromProfile, 'Copy qr image from profile'),
    qrOneImgFlag: () => _t(translations.main.qrOneImgFlg, 'qr image one image pattern')
  }
};

export type MessageFunc = () => [string, ...any[]];
