import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  FetchARProjectList,
  ARProjectListPageResult
} from 'src/api_v2/ar-project/types';
import callApi from 'src/api_v2/ar-project/ar-project-list-api';
import {
  selectPage,
  selectPageSize,
  selectTemplateId,
  selectSearch,
  selectSort,
  selectUserId
} from './selector';
import { actions } from './slice';

function* fetchList() {
  try {
    const page = yield select(selectPage);
    const pageSize = yield select(selectPageSize);
    const search = yield select(selectSearch);
    const sort = yield select(selectSort);
    const template_id = yield select(selectTemplateId);
    const user_id = yield select(selectUserId);
    const queryParams = new FetchARProjectList(
      page,
      pageSize,
      search,
      sort,
      template_id === "all" ? "" :template_id,
      user_id === "all" ? "" :user_id,
    );
    const result: ARProjectListPageResult = yield call(
      callApi.fetchList,
      queryParams
      );

    yield put(actions.listFetched(result));
  } catch (err) {
    console.log(err);
    yield put(actions.listFetchedError());
  }
}

function* deleteItem(action: PayloadAction<number>) {
  try {
    const userId = action.payload;
    if (userId) {
      yield call(callApi.deleteItem, userId);

      yield put(actions.deletedItem());
      yield call(fetchList)
    }
  } catch (err) {
    console.log(err);
    yield put(actions.useDeletedError());
  }
}

function* fetchTotalList() {
  try {
    const search = yield select(selectSearch);
    const sort = yield select(selectSort);

    const queryParams = new FetchARProjectList(
      1,
      Number.MAX_SAFE_INTEGER,
      search,
      sort,
    );
    const result: ARProjectListPageResult = yield call(
      callApi.fetchList,
      queryParams
    );

    yield put(actions.listFetched(result));
  } catch (err) {
    console.log(err);
    yield put(actions.listFetchedError());
  }
}

function* exportStatsCSV() {
  try {
    yield call(callApi.exportARProjectsCreationStatsCSV)

    yield put(actions.statsCSVExported())
  } catch (err) {
    console.log(err);
    yield put(actions.statsCSVExportedFail());
  }
}

function* exportUnscreenHistory() {
  try {
    yield call(callApi.exportUnscreenHistory,0)

    yield put(actions.unscreenHistoryExported())
  } catch (err) {
    console.log(err);
    yield put(actions.unscreenHistoryExportedFail());
  }
}

function* updateStatus(action: PayloadAction<{id: number, status: boolean}>) {
  try {
    yield call(callApi.updateStatus, action.payload.id, action.payload.status)
  } catch (err) {
    console.log(err);
  }
}

export default function* watchListSaga() {
  yield takeLatest(actions.fetchList.type, fetchList);
  yield takeLatest(actions.deleteItem.type, deleteItem);
  yield takeEvery(actions.fetchTotalList.type, fetchTotalList);
  yield takeEvery(actions.exportUnscreenHistory.type, exportUnscreenHistory);
  yield takeEvery(actions.exportStatsCSV.type, exportStatsCSV);
  yield takeEvery(actions.updateStatus.type, updateStatus);
}
