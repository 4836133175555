import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store/configureStore';
import { State } from './slice';

const selectDomain = (state: RootState) => {
  return state.arProject;
};

export const selectList = createSelector(
  [selectDomain],
  (arProject: State) => arProject.data
);

export const selectPagination = createSelector(
  [selectDomain],
  (arProject: State) => ({
    page: arProject.page,
    pageSize: arProject.pageSize,
    search: arProject.search,
    sort: arProject.sort,
  })
);

export const selectSearch = createSelector(
  [selectDomain],
  (arProject: State) => arProject.search
);

export const selectSort = createSelector(
  [selectDomain],
  (arProject: State) => arProject.sort
);

export const selectPage = createSelector(
  [selectDomain],
  (arProject: State) => arProject.page
);

export const selectPageSize = createSelector(
  [selectDomain],
  (arProject: State) => arProject.pageSize
);

export const selectTotal = createSelector(
  [selectDomain],
  (arProject: State) => arProject.total
)

export const selectLoading = createSelector(
  [selectDomain],
  (arProject: State) => arProject.loading
)

export const selectDeleted = createSelector(
  [selectDomain],
  (arProject: State) => arProject.deleted
)

export const selectTemplateId = createSelector(
  [selectDomain],
  (arProject: State) => arProject.template_id
)

export const selectUserId = createSelector(
  [selectDomain],
  (arProject: State) => arProject.user_id
)


export const selectExporting = createSelector(
  [selectDomain],
  (arProject: State) => arProject.exporting
);

export const selectStatsCSVExporting = createSelector(
  [selectDomain],
  (arProject: State) => arProject.stats_exporting
);